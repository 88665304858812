import { useTranslation } from "react-i18next";
import classNames from "classnames";
import { TypographyComponent } from "gx-npm-ui";
import FadeContainerComponent from "../../ui/fade-container.component";
import ProposalReviewButtonIcon from "./proposal-review-button.icon";
import ExternalLinkIcon from "./external-link.icon";
import styles from "./proposal-review-button.styles.module.scss";

type ProposalReviewButtonComponentProps = {
  isExpanded?: boolean;
};

const ProposalReviewButtonComponent = ({ isExpanded = false }: ProposalReviewButtonComponentProps) => {
  const { t } = useTranslation();
  const buttonUrlPath = `${window.gcomWwwConnection}/x/proposal-review`;
  const tabLabel = t("Proposal review");

  return (
    <a
      aria-label={tabLabel}
      tabIndex={0}
      href={buttonUrlPath}
      target="_blank"
      rel="noreferrer"
      className={classNames(styles.itemButton)}
    >
      <div className={styles.itemButtonIconContainer}>
        <ProposalReviewButtonIcon />
      </div>
      <FadeContainerComponent in={isExpanded}>
        <div className={styles.itemButtonLinkContainer}>
          <div className={styles.itemButtonTextContainer}>
            <TypographyComponent
              color={"stone"}
              boldness={"medium"}
              rootClassName={styles.itemButtonText}
              styling={"p2"}
            >
              {tabLabel}
            </TypographyComponent>
          </div>
          <div className={styles.externalLink}>
            <ExternalLinkIcon />
          </div>
        </div>
      </FadeContainerComponent>
    </a>
  );
};

export default ProposalReviewButtonComponent;
