import { useTranslation } from "react-i18next";

const HomeButtonIconV2 = () => {
  const { t } = useTranslation();

  return (
    <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
      <title>{t("Home icon")}</title>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M23.3692 15.1856C23.6984 14.9381 24.1516 14.9381 24.4808 15.1856L31.4808 20.4476C31.7133 20.6223 31.85 20.8962 31.85 21.187V31.674C31.85 32.1848 31.4359 32.599 30.925 32.599H26.1557C25.6448 32.599 25.2307 32.1848 25.2307 31.674V26.2678H22.6193V31.674C22.6193 32.1848 22.2052 32.599 21.6943 32.599H16.925C16.4141 32.599 16 32.1848 16 31.674V21.187C16 20.8962 16.1367 20.6223 16.3692 20.4476L23.3692 15.1856ZM24.5713 17.0641C24.1885 16.7761 23.6615 16.7761 23.2787 17.0641L17.8787 21.1268C17.6088 21.3299 17.45 21.648 17.45 21.9858V31.1508H21.1693V25.7446C21.1693 25.2338 21.5835 24.8196 22.0943 24.8196H25.7557C26.2665 24.8196 26.6807 25.2338 26.6807 25.7446V31.1508H30.4V21.9858C30.4 21.648 30.2412 21.3299 29.9713 21.1268L24.5713 17.0641Z"
        fill="#BBB9B8"
      />
    </svg>
  );
};

export default HomeButtonIconV2;
