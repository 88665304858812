const MyTasksButtonIconV2 = () => {
  return (
    <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M17.4996 18.75H17.2496V19V21V21.25H17.4996H19.4996H19.7496V21V19V18.75H19.4996H17.4996ZM17.3233 29.3472L17.4998 29.5229L17.6762 29.347L21.5608 25.4708C21.8528 25.1794 22.3257 25.1797 22.6175 25.4714C22.9094 25.7633 22.9094 26.2367 22.6175 26.5286L18.0299 31.1161C17.737 31.409 17.2622 31.409 16.9693 31.1161L14.8822 29.0291C14.59 28.7369 14.59 28.2631 14.8822 27.9709C15.1739 27.6792 15.6467 27.6787 15.9391 27.9697L17.3233 29.3472ZM16.4996 17.25H20.4996C20.9138 17.25 21.2496 17.5858 21.2496 18V22C21.2496 22.4142 20.9138 22.75 20.4996 22.75H16.4996C16.0854 22.75 15.7496 22.4142 15.7496 22V18C15.7496 17.5858 16.0854 17.25 16.4996 17.25ZM25.4996 19.25H33.0509C33.4651 19.25 33.8009 19.5858 33.8009 20C33.8009 20.4142 33.4651 20.75 33.0509 20.75H25.4996C25.0854 20.75 24.7496 20.4142 24.7496 20C24.7496 19.5858 25.0854 19.25 25.4996 19.25ZM25.4996 27.25H33.0509C33.4651 27.25 33.8009 27.5858 33.8009 28C33.8009 28.4142 33.4651 28.75 33.0509 28.75H25.4996C25.0854 28.75 24.7496 28.4142 24.7496 28C24.7496 27.5858 25.0854 27.25 25.4996 27.25Z"
        fill="#BBB9B8"
      />
    </svg>
  );
};

export default MyTasksButtonIconV2;
