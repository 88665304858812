const VendorFitButtonIcon = () => {
  return (
    <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M14.8182 30C14.3663 30 14 29.6337 14 29.1818C14 28.7299 14.3663 28.3636 14.8182 28.3636H19.873C20.3249 28.3636 20.6912 28.7299 20.6912 29.1818C20.6912 29.6337 20.3249 30 19.873 30H14.8182ZM14.8182 24.8182C14.3663 24.8182 14 24.4519 14 24C14 23.5481 14.3663 23.1818 14.8182 23.1818H26.0224C26.4742 23.1818 26.8406 23.5481 26.8406 24C26.8406 24.4519 26.4742 24.8182 26.0224 24.8182H14.8182ZM14.8182 19.6364C14.3663 19.6364 14 19.2701 14 18.8182C14 18.3663 14.3663 18 14.8182 18H32.1818C32.6337 18 33 18.3663 33 18.8182C33 19.2701 32.6337 19.6364 32.1818 19.6364H14.8182Z"
        fill="#BBB9B8"
      />
    </svg>
  );
};

export default VendorFitButtonIcon;
