const BrowseMarketsButtonIconV2 = () => {
  return (
    <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M15.7217 33.0934C15.2406 33.0934 14.8333 32.9267 14.5 32.5934C14.1667 32.2601 14 31.8529 14 31.372V22.4891C14 22.0946 14.3198 21.7748 14.7143 21.7748C15.1088 21.7748 15.4286 22.0946 15.4286 22.4891V31.372C15.4286 31.4452 15.4591 31.5123 15.5202 31.5734C15.5812 31.6344 15.6483 31.6648 15.7217 31.6648H30.1905C30.585 31.6648 30.9048 31.9846 30.9048 32.3791C30.9048 32.7736 30.585 33.0934 30.1905 33.0934H15.7217ZM19.055 29.7601C18.5739 29.7601 18.1667 29.5935 17.8333 29.2603C17.5 28.927 17.3333 28.5198 17.3333 28.0386V19.3317C17.3333 18.7795 17.781 18.3317 18.3333 18.3317H22.2417V16.6284C22.2417 16.1473 22.4083 15.7401 22.7417 15.4067C23.075 15.0734 23.4822 14.9067 23.9633 14.9067H27.37C27.851 14.9067 28.2581 15.0734 28.5914 15.4067C28.9248 15.7401 29.0914 16.1473 29.0914 16.6284V18.3317H33C33.5523 18.3317 34 18.7795 34 19.3317V28.0386C34 28.5198 33.8333 28.927 33.5 29.2603C33.1667 29.5935 32.7594 29.7601 32.2783 29.7601H19.055ZM19.055 28.3317H32.2783C32.3517 28.3317 32.4188 28.3012 32.4798 28.2401C32.5409 28.179 32.5714 28.1118 32.5714 28.0386V19.7603H18.7619V28.0386C18.7619 28.1118 18.7924 28.179 18.8533 28.2401C18.9144 28.3012 18.9817 28.3317 19.055 28.3317ZM23.6702 18.3317H27.6631V16.6284C27.6631 16.5551 27.6325 16.4879 27.5714 16.427C27.5103 16.3659 27.4432 16.3353 27.37 16.3353H23.9633C23.8902 16.3353 23.823 16.3659 23.7619 16.427C23.7008 16.4879 23.6702 16.5551 23.6702 16.6284V18.3317Z"
        fill="#BBB9B8"
      />
    </svg>
  );
};

export default BrowseMarketsButtonIconV2;
