import { ClickAwayListener, Menu } from "@mui/material";
import { colorPalette } from "gx-npm-common-styles";
import { getAsyncRequest, useUserState } from "gx-npm-lib";
import { publish } from "gx-npm-messenger-util";
import { IconButton, TypographyComponent, useFeatureFlag, UsernameDisplay } from "gx-npm-ui";
import React, { Dispatch, KeyboardEvent, MouseEvent, SetStateAction, useState } from "react";
import { useTranslation } from "react-i18next";
import { navigateToUrl } from "single-spa";
import FadeContainerComponent from "../../ui/fade-container.component";
import UserLogoutButtonIcon from "./user-logout-button.icon";
import styles from "./user-logout-section.styles.module.scss";
import { GCOM_4480_GCOM_4482_gcomLogin } from "../../../lib/feature-flags";

type UserLogoutSectionComponentProps = {
  isExpanded?: boolean;
  setLogoutMenuOpen: Dispatch<SetStateAction<boolean>>;
};
const UserLogoutSectionComponent = ({ isExpanded = false, setLogoutMenuOpen }: UserLogoutSectionComponentProps) => {
  const { t } = useTranslation();
  const { hasUserState, firstName, lastName } = useUserState();
  const [menuAnchorEl, setMenuAnchorEl] = useState<HTMLMenuElement | null>(null);
  const [menuOpen, setMenuOpen] = useState(false);
  const isFFGCOM4482On = useFeatureFlag(GCOM_4480_GCOM_4482_gcomLogin);

  const handleLogout = async () => {
    setMenuOpen(false);
    const res = await getAsyncRequest(isFFGCOM4482On ? "/api/v3/auth/logout" : "/api/v2/auth/logout");
    if (res.status === 200) {
      if (window.Intercom) {
        window.Intercom("shutdown");
      }
      sessionStorage.clear();
      publish("USER_STATE_CLEAR");
      publish("USER_LOGGED_OUT");
      publish("WS_DISCONNECT");
      navigateToUrl("/login?logout=true");
    }
  };

  return (
    <div className={styles.itemContainer}>
      <UsernameDisplay
        rootClassName={styles.userAvatarContainer}
        name={`${firstName} ${lastName}`}
        showName={false}
        isUnknown={!hasUserState}
      />
      <FadeContainerComponent in={isExpanded}>
        <div className={styles.userNameLogoutContainer}>
          <TypographyComponent rootClassName={styles.userTextContainer} type={"p3"} boldness={"medium"} color={"white"}>
            {firstName}
          </TypographyComponent>
          <IconButton
            ariaLabel={t("user menu button")}
            hoverFill={colorPalette.basic.white.hex}
            hoverBackgroundColor={colorPalette.brand.gartnerBlue.hex}
            onClick={(e: MouseEvent<HTMLMenuElement> | KeyboardEvent<HTMLMenuElement>) => {
              setMenuAnchorEl(e.currentTarget);
              setMenuOpen(true);
              setLogoutMenuOpen(true);
            }}
          >
            <UserLogoutButtonIcon />
          </IconButton>
        </div>
      </FadeContainerComponent>
      <Menu
        open={menuOpen}
        disablePortal={true}
        anchorEl={menuAnchorEl}
        classes={{ paper: styles.muiMenuPaper }}
        anchorOrigin={{ vertical: "top", horizontal: "left" }}
        transformOrigin={{ vertical: "bottom", horizontal: "left" }}
      >
        <div>
          <ClickAwayListener
            onClickAway={() => {
              setMenuOpen(false);
              setLogoutMenuOpen(false);
            }}
          >
            <button className={styles.logoutMenuItem} onClick={handleLogout}>
              <TypographyComponent type={"p3"} boldness={"medium"}>
                {t("Log out")}
              </TypographyComponent>
            </button>
          </ClickAwayListener>
        </div>
      </Menu>
    </div>
  );
};

export default UserLogoutSectionComponent;
