import { useTranslation } from "react-i18next";

const VisitGartnerButtonIconV2 = () => {
  const { t } = useTranslation();

  return (
    <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
      <title>{t("Gartner icon")}</title>
      <path
        d="M31 30.4591C29.1419 31.4781 27.2838 32.0665 24.8314 31.994C20.2838 31.8533 17 28.9541 17 24.0595C17 19.1863 20.3129 16.0739 24.8314 16.0014C27.1217 15.9673 29.171 16.5599 30.7755 18.0138L28.8468 20.0177C27.8533 19.1522 26.3985 18.6448 24.6526 18.7258C21.8094 18.8622 20.0968 20.9812 20.0552 23.8975C20.0178 27.0568 21.905 29.2312 24.8563 29.3122C26.2447 29.3463 27.2381 29.1545 28.1027 28.758V25.5304H25.035L25.2429 22.8828H31V30.4591Z"
        fill="#BBB9B8"
      />
    </svg>
  );
};

export default VisitGartnerButtonIconV2;
