import { useTranslation } from "react-i18next";

const MyEvalsButtonIconV2 = () => {
  const { t } = useTranslation();

  return (
    <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
      <title>{t("Evaluations icon")}</title>
      <path
        d="M18.6113 18.169C18.1963 18.169 17.8539 18.5062 17.8539 18.9296C17.8539 19.353 18.1963 19.6901 18.6113 19.6901H27.488C27.9029 19.6901 28.2453 19.353 28.2453 18.9296C28.2453 18.5062 27.9029 18.169 27.488 18.169H18.6113Z"
        fill="#BBB9B8"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M17.0959 15C15.9505 15 15 15.9048 15 17.0282V20.831C15 21.9543 15.9505 22.8592 17.0959 22.8592H30.9041C32.0495 22.8592 33 21.9543 33 20.831V17.0282C33 15.9048 32.0495 15 30.9041 15H17.0959ZM16.5147 17.0282C16.5147 16.8007 16.7304 16.5211 17.0959 16.5211H30.9041C31.2696 16.5211 31.4853 16.8007 31.4853 17.0282V20.831C31.4853 21.0584 31.2696 21.338 30.9041 21.338H17.0959C16.7304 21.338 16.5147 21.0584 16.5147 20.831V17.0282Z"
        fill="#BBB9B8"
      />
      <path
        d="M18.6103 28.3099C18.1953 28.3099 17.853 28.647 17.853 29.0704C17.853 29.4938 18.1953 29.831 18.6103 29.831H23.6827C24.0977 29.831 24.44 29.4938 24.44 29.0704C24.44 28.647 24.0977 28.3099 23.6827 28.3099H18.6103Z"
        fill="#BBB9B8"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M17.0959 25.1408C15.9505 25.1408 15 26.0456 15 27.169V30.9718C15 32.0952 15.9505 33 17.0959 33H30.9041C32.0495 33 33 32.0952 33 30.9718V27.169C33 26.0456 32.0495 25.1408 30.9041 25.1408H17.0959ZM16.5147 27.169C16.5147 26.9416 16.7304 26.662 17.0959 26.662H30.9041C31.2696 26.662 31.4853 26.9416 31.4853 27.169V30.9718C31.4853 31.1993 31.2696 31.4789 30.9041 31.4789H17.0959C16.7304 31.4789 16.5147 31.1993 16.5147 30.9718V27.169Z"
        fill="#BBB9B8"
      />
    </svg>
  );
};

export default MyEvalsButtonIconV2;
