import { useTranslation } from "react-i18next";
import classNames from "classnames";
import {
  FeatureFlagBooleanContainer,
  FeatureFlagBooleanOff,
  FeatureFlagBooleanOn,
  TypographyComponent,
  useFeatureFlag,
} from "gx-npm-ui";
import VisitGartnerButtonIcon from "./visit-gartner-button.icon";
import VisitGartnerButtonIconV2 from "./visit-gartner-button-v2.icon";
import ExternalLinkIcon from "./external-link.icon";
import FadeContainerComponent from "../../ui/fade-container.component";
import styles from "./visit-gartner-button.styles.module.scss";
import { GCOM_4480__buysmart20 } from "./../../../lib/feature-flags";

type VisitGartnerButtonComponentProps = {
  isExpanded?: boolean;
};

const VisitGartnerButtonComponent = ({ isExpanded = false }: VisitGartnerButtonComponentProps) => {
  const isGCOM4483On = useFeatureFlag(GCOM_4480__buysmart20);

  const { t } = useTranslation();
  const buttonUrlPath = isGCOM4483On ? window.gcomWwwConnection : "https://www.gartner.com";
  const tabLabel = isGCOM4483On ? t("Gartner.com") : t("Visit Gartner.com");

  return (
    <FeatureFlagBooleanContainer flagName={GCOM_4480__buysmart20}>
      <FeatureFlagBooleanOff>
        <FadeContainerComponent in={isExpanded}>
          <a
            aria-label={tabLabel}
            tabIndex={0}
            href={buttonUrlPath}
            target="_blank"
            rel="noreferrer"
            className={styles.itemButton}
          >
            <div className={styles.itemButtonIconContainer}>
              <VisitGartnerButtonIcon />
            </div>
            <div className={styles.itemButtonTextContainer}>
              <TypographyComponent
                color={"stone"}
                boldness={"medium"}
                rootClassName={styles.itemButtonText}
                styling={"p2"}
              >
                {tabLabel}
              </TypographyComponent>
            </div>
          </a>
        </FadeContainerComponent>
      </FeatureFlagBooleanOff>
      <FeatureFlagBooleanOn>
        <a
          aria-label={tabLabel}
          tabIndex={0}
          href={buttonUrlPath}
          target="_blank"
          rel="noreferrer"
          className={classNames(styles.itemButton, styles.itemButtonGCOM4483)}
        >
          <div className={styles.itemButtonIconContainer}>
            <VisitGartnerButtonIconV2 />
          </div>
          <FadeContainerComponent in={isExpanded}>
            <div className={styles.itemButtonLinkContainerGCOM4483}>
              <div className={styles.itemButtonTextContainer}>
                <TypographyComponent
                  color={"stone"}
                  boldness={"medium"}
                  rootClassName={styles.itemButtonText}
                  styling={"p2"}
                >
                  {tabLabel}
                </TypographyComponent>
              </div>
              <div className={styles.externalLinkGCOM4483}>
                <ExternalLinkIcon />
              </div>
            </div>
          </FadeContainerComponent>
        </a>
      </FeatureFlagBooleanOn>
    </FeatureFlagBooleanContainer>
  );
};

export default VisitGartnerButtonComponent;
