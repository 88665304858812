import { useTranslation } from "react-i18next";
import classNames from "classnames";
import { useUserState } from "gx-npm-lib";
import { useFeatureFlag } from "gx-npm-ui";
import HomeButtonComponent from "./home-button/home-button.component";
import BrowseMarketsButtonComponent from "./browse-markets-button/browse-markets-button.component";
import MyEvalsButtonComponent from "./my-evals-button/my-evals-button.component";
import MyTasksButtonComponent from "./my-tasks-button/my-tasks-button.component";
import CurrentEvalSectionComponent from "./current-eval-section/current-eval-section.component";
import VendorFitButtonComponent from "./vendor-fit-button/vendor-fit-button.component";
import ProposalReviewButtonComponent from "./proposal-review-button/proposal-review-button.component";
import styles from "./navigation-buttons-v2.styles.module.scss";
import { GCOM_4480__buysmart20 } from "../../lib/feature-flags";

type NavigationButtonsV2Props = {
  isExpanded?: boolean;
};

const NavigationButtonsV2Component = ({ isExpanded = false }: NavigationButtonsV2Props) => {
  const { t } = useTranslation();
  const { hasUserState, hasInitiatives, hasTasks, isEntitled } = useUserState();
  const isGCOM4483On = useFeatureFlag(GCOM_4480__buysmart20);

  return (
    <nav>
      {hasUserState && (
        <ul aria-label={t("button list")} className={styles.navButtonList}>
          {(isEntitled || hasInitiatives || !hasTasks) && (
            <li className={classNames(isGCOM4483On ? styles.navButtonItemGCOM4483 : styles.navButtonItem)}>
              <HomeButtonComponent isExpanded={isExpanded} />
            </li>
          )}
          {(isEntitled || hasInitiatives || !hasTasks) && (
            <li className={classNames(isGCOM4483On ? styles.navButtonItemGCOM4483 : styles.navButtonItem)}>
              <BrowseMarketsButtonComponent isExpanded={isExpanded} />
            </li>
          )}
          {isGCOM4483On && (isEntitled || hasInitiatives || !hasTasks) && (
            <li className={classNames(isGCOM4483On ? styles.navButtonItemGCOM4483 : styles.navButtonItem)}>
              <VendorFitButtonComponent isExpanded={isExpanded} />
            </li>
          )}
          {(isEntitled || hasInitiatives || !hasTasks) && (
            <li className={classNames(isGCOM4483On ? styles.navButtonItemGCOM4483 : styles.navButtonItem)}>
              <MyEvalsButtonComponent isExpanded={isExpanded} />
            </li>
          )}
          {hasTasks && (
            <li className={classNames(isGCOM4483On ? styles.navButtonItemGCOM4483 : styles.navButtonItem)}>
              <MyTasksButtonComponent isExpanded={isExpanded} />
            </li>
          )}
          {!isGCOM4483On && (
            <li>
              <CurrentEvalSectionComponent isExpanded={isExpanded} />
            </li>
          )}
          {isGCOM4483On && (isEntitled || !hasTasks) && (
            <li className={classNames(isGCOM4483On ? styles.navButtonItemGCOM4483 : styles.navButtonItem)}>
              <ProposalReviewButtonComponent isExpanded={isExpanded} />
            </li>
          )}
        </ul>
      )}
    </nav>
  );
};

export default NavigationButtonsV2Component;
