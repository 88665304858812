import { useTranslation } from "react-i18next";
import { TypographyComponent, useFeatureFlag } from "gx-npm-ui";
import HelpCenterButtonIcon from "./help-center-button.icon";
import HelpCenterButtonIconV2 from "./help-center-button-v2.icon";
import FadeContainerComponent from "../../ui/fade-container.component";
import styles from "./help-center-button.styles.module.scss";
import React from "react";
import { GCOM_4480__buysmart20 } from "./../../../lib/feature-flags";

type HelpCenterButtonComponentProps = {
  isExpanded?: boolean;
};

const HelpCenterButtonComponent = ({ isExpanded = false }: HelpCenterButtonComponentProps) => {
  const { t } = useTranslation();
  const buttonUrlPath = "https://intercom.help/gartner-buysmart-help-center";
  const tabLabel = t("Help center");
  const isGCOM4483On = useFeatureFlag(GCOM_4480__buysmart20);

  return (
    <a
      aria-label={tabLabel}
      tabIndex={0}
      href={buttonUrlPath}
      target="_blank"
      rel="noreferrer"
      className={styles.itemButton}
    >
      <div className={styles.itemButtonIconContainer}>
        {isGCOM4483On ? <HelpCenterButtonIconV2 /> : <HelpCenterButtonIcon />}
      </div>
      <FadeContainerComponent in={isExpanded}>
        <div className={styles.itemButtonTextContainer}>
          <TypographyComponent color={"stone"} boldness={"medium"} rootClassName={styles.itemButtonText} styling={"p2"}>
            {tabLabel}
          </TypographyComponent>
        </div>
      </FadeContainerComponent>
    </a>
  );
};

export default HelpCenterButtonComponent;
