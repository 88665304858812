const ProposalReviewButtonIcon = () => {
  return (
    <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M24.0335 20C23.6469 20 23.3335 20.3134 23.3335 20.7V21.1053H21.9002C21.6994 21.1053 21.5357 21.1742 21.4021 21.3128C21.2681 21.4515 21.2002 21.623 21.2002 21.8342V25.15C21.2002 25.3612 21.268 25.5326 21.402 25.6713C21.5356 25.8099 21.6994 25.8789 21.9002 25.8789H25.4669V27.7368H21.9291C21.5266 27.7368 21.2002 28.0632 21.2002 28.4658C21.2002 28.8684 21.5266 29.1947 21.9291 29.1947H23.3335V29.6C23.3335 29.9866 23.6469 30.3 24.0335 30.3C24.4201 30.3 24.7335 29.9866 24.7335 29.6V29.1947H26.1669C26.3676 29.1947 26.5315 29.1257 26.6653 28.9871C26.7991 28.8485 26.8669 28.677 26.8669 28.4658V25.15C26.8669 24.9388 26.7991 24.7673 26.6653 24.6287C26.5315 24.49 26.3676 24.4211 26.1669 24.4211H22.6002V22.5632H26.1379C26.5405 22.5632 26.8669 22.2368 26.8669 21.8342C26.8669 21.4316 26.5405 21.1053 26.1379 21.1053H24.7335V20.7C24.7335 20.3134 24.4201 20 24.0335 20Z"
        fill="#BBB9B8"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16.56 33.9197C16.9333 34.3066 17.3894 34.5 17.9283 34.5H30.0717C30.6106 34.5 31.0667 34.3066 31.44 33.9197C31.8133 33.5329 32 33.0603 32 32.502V19.7065C32 19.4474 31.8995 19.1985 31.7196 19.012L26.6949 13.8056C26.5064 13.6103 26.2467 13.5 25.9753 13.5H17.9283C17.3894 13.5 16.9333 13.6934 16.56 14.0803C16.1867 14.4671 16 14.9397 16 15.498V32.502C16 33.0603 16.1867 33.5329 16.56 33.9197ZM25.6 18.4513C25.6 19.0036 26.0477 19.4513 26.6 19.4513H30.4V32.502C30.4 32.5871 30.3658 32.665 30.2973 32.7357C30.2291 32.8066 30.1539 32.8421 30.0717 32.8421H17.9283C17.8461 32.8421 17.7709 32.8066 17.7027 32.7357C17.6342 32.665 17.6 32.5871 17.6 32.502V15.498C17.6 15.4129 17.6342 15.335 17.7027 15.2643C17.7709 15.1934 17.8461 15.1579 17.9283 15.1579H25.6V18.4513Z"
        fill="#BBB9B8"
      />
    </svg>
  );
};

export default ProposalReviewButtonIcon;
