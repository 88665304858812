const ExternalLinkIcon = () => {
  return (
    <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g opacity="0.5">
        <path
          d="M6.69946 16.5079L15.6318 7.93286L7.24055 7.93285L7.25187 6.43164L18.3004 6.43164L18.3004 17.0382L16.7366 17.0491L16.7366 8.99349L7.80428 17.5685L6.69946 16.5079Z"
          fill="#BBB9B8"
        />
      </g>
    </svg>
  );
};

export default ExternalLinkIcon;
