import classNames from "classnames";
import { TypographyComponent, useFeatureFlag } from "gx-npm-ui";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router";
import { navigateToUrl } from "single-spa";
import FadeContainerComponent from "../../ui/fade-container.component";
import styles from "./my-evals-button.styles.module.scss";
import MyEvalsButtonIcon from "./my-evals-button.icon";
import MyEvalsButtonIconV2 from "./my-evals-button-v2.icon";
import { GCOM_4480__buysmart20 } from "../../../lib/feature-flags";

type MyEvalsButtonComponentProps = {
  isExpanded?: boolean;
};

const MyEvalsButtonComponent = ({ isExpanded = false }: MyEvalsButtonComponentProps) => {
  const isGCOM4483On = useFeatureFlag(GCOM_4480__buysmart20);
  const { t } = useTranslation();
  const [isSelected, setIsSelected] = useState(false);
  const location = useLocation();
  const buttonUrlPath = "/s/evaluations";
  const tabLabel = isGCOM4483On ? t("Evaluations") : t("My evaluations");
  const handleClick = (e: React.MouseEvent<HTMLAnchorElement> | React.KeyboardEvent<HTMLAnchorElement>) => {
    e.preventDefault();
    navigateToUrl(buttonUrlPath);
  };

  useEffect(() => {
    if (isGCOM4483On) {
      setIsSelected(location.pathname === buttonUrlPath || location.pathname.includes("/s/evaluation"));
    } else {
      setIsSelected(location.pathname === buttonUrlPath);
    }
  }, [location.pathname, isGCOM4483On]);

  return (
    <a
      aria-label={tabLabel}
      tabIndex={0}
      onKeyDown={(e) => e.key === "Enter" && handleClick(e)}
      onClick={handleClick}
      href={buttonUrlPath}
      className={classNames(styles.itemButton, isSelected && styles.itemSelected)}
    >
      <div className={styles.itemButtonIconContainer}>
        {isGCOM4483On ? <MyEvalsButtonIconV2 /> : <MyEvalsButtonIcon />}
      </div>
      <FadeContainerComponent in={isExpanded}>
        <div className={styles.itemButtonTextContainer}>
          <TypographyComponent rootClassName={styles.itemButtonText} color={"stone"} boldness={"medium"} styling={"p2"}>
            {tabLabel}
          </TypographyComponent>
        </div>
      </FadeContainerComponent>
    </a>
  );
};

export default MyEvalsButtonComponent;
